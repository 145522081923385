/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed } from 'vue'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useExportState() {
  const exportStoreRepository = useService('exportStoreRepository')
  const exportCollection = computed(() => exportStoreRepository.getExports())

  return {
    exportCollection,
  }
}
