/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CreateTransactionalDataPivotExportCommand from '~/src/Application/Export/Command/CreateTransactionalDataPivotExportCommand'
import CreateTransactionalDataTableExportCommand from '~/src/Application/Export/Command/CreateTransactionalDataTableExportCommand'
import DownloadExportCommand from '~/src/Application/Export/Command/DownloadExportCommand'
import type Export from '~/src/Domain/Export/Export'
import type GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useExportCommands() {
  const commandBus = useService('commandBus')

  const downloadExport = async (item: Export): Promise<void> => {
    const command = new DownloadExportCommand(item)
    await commandBus.dispatch(DownloadExportCommand.NAME, command)
  }

  const createTransactionalDataTableExport = async (
    iri: GenericIri,
    extension: 'csv' | 'xlsx',
    fields: string[],
    url: string,
  ): Promise<void> => {
    const command = new CreateTransactionalDataTableExportCommand(iri, extension, fields, url)
    await commandBus.dispatch(CreateTransactionalDataTableExportCommand.NAME, command)
  }

  const createTransactionalDataPivotExport = async (
    iri: GenericIri,
    extension: 'csv' | 'xlsx',
    rows: string[],
    columns: string[],
    values: { field: string, valueType: 'count' | 'sum' }[],
    url: string,
  ): Promise<void> => {
    const command = new CreateTransactionalDataPivotExportCommand(
      iri,
      extension,
      rows,
      columns,
      values,
      url,
    )
    await commandBus.dispatch(CreateTransactionalDataPivotExportCommand.NAME, command)
  }

  return {
    downloadExport,
    createTransactionalDataTableExport,
    createTransactionalDataPivotExport,
  }
}
